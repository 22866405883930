import { Route, Routes } from "react-router-dom";
import Home from "./Ui/Home/Home";
import "./App.css"
import HairTransplantation from "./Ui/HairTransplantation/HairTransplantation";

const App = () => {

    return (
        <Routes>
            <Route path="/" element={<Home />} /> 
            <Route path="/HairTransplantation" element={<HairTransplantation />} />
        </Routes>
    )
}

export default App