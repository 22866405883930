import { useTranslation } from "react-i18next"
import Footer from "../../Components/Footer/Footer"
import GmailForm from "../../Components/GmailForm/GmailForm"
import Main from "../../Components/Main/Main"
import MobileNavigation from "../../Components/MobileNavigation/MobileNavigation"
import Navigation from "../../Components/Navigation/Navigation"
import Subtitle from "../../Components/Subtitle/Subtitle"
import Wrapper from "../../Components/Wrapper"
import "./HairTransplantation.css"

const HairTransplantation = () => {

    window.scrollTo(0, 0)

    const { t } = useTranslation()

    return (
        <Wrapper>
            <header>
                <Navigation />
                <MobileNavigation />
                <img width={"100%"} height={"auto"} src="./Assets/Images/header2.jpg" alt="" />
            </header>

            <Main>
                <div className="hairTransplantationRow">
                    <div className="hairTransplantationRowCard">
                        <h2 className="hairTransplantationRowSubtitle"> {t("ht1")} </h2>
                        <p className="hairTransplantationRowText"> {t("ht2")} </p>
                    </div>
                    <img className="image13" width={"500px"} height={"500px"} src="./Assets/Images/image13.jpg" alt="" />
                </div>

                <Subtitle> {t("ht3")} </Subtitle>
                <div className="hairTransplantationRow">
                    <video className="vid" poster="./Assets/Images/poster1.jpg" controls>
                        <source src="./Assets/Videos/video1.mp4" />
                    </video>
                    <div className="hairTransplantationRowCard">
                        <p className="hairTransplantationRowTextBold"> {t("ht4")}  </p>
                        <p className="hairTransplantationRowText"> {t("ht5")} </p>
                    </div>
                </div>

                <Subtitle> {t("ht6")} </Subtitle>
                <div className="hairTransplantationRow">
                    <div className="hairTransplantationRowCard">
                        <p className="hairTransplantationRowTextBold"> {t("ht7")} </p>
                        <p className="hairTransplantationRowText"> {t("ht8")} </p>
                    </div>
                    <video className="vid" poster="./Assets/Images/poster2.jpg" controls>
                        <source src="./Assets/Videos/video2.mp4" />
                    </video>
                </div>

                <Subtitle> {t("ht9")} </Subtitle>
                <div className="hairTransplantationRow">
                    <div className="hairTransplantationRowCard">
                        <p className="hairTransplantationRowTextBold"> {t("ht10")} </p>
                        <p className="hairTransplantationRowText"> {t("ht11")} </p>
                    </div>
                    <img className="image13" src="./Assets/Images/image14.jpg" alt="" />
                </div>

            </Main>

            <div className="doctorCard">
                <Subtitle> {t("ht12")} </Subtitle>
                <div className="doctorCardRow">
                    <div className="doctor">
                        <img className="doctorImage" src="./Assets/Images/doctor1.jpg" alt="" />
                        <p className="hairTransplantationRowTextBold"> {t("ht16")} </p>
                    </div>
                    <p className="hairTransplantationRowText"> {t("ht13")} </p>
                </div>
            </div>

            <Main>
                <Subtitle> {t("ht14")} </Subtitle>
                <p className="gmailFormText"> {t("ht15")} </p>
                <GmailForm />
            </Main>

            <Footer />
        </Wrapper>
    )
}

export default HairTransplantation
