import { useTranslation } from "react-i18next"
import Main from "../../Components/Main/Main"
import MobileNavigation from "../../Components/MobileNavigation/MobileNavigation"
import Navigation from "../../Components/Navigation/Navigation"
import Subtitle from "../../Components/Subtitle/Subtitle"
import Wrapper from "../../Components/Wrapper"
import "./Home.css"
import AdvantageCard from "../../Components/AdvantageCard/AdvantageCard"
import Footer from "../../Components/Footer/Footer"
import GmailForm from "../../Components/GmailForm/GmailForm"
import Comments from "../../Components/Comments/Comments"
import { Link } from "react-router-dom"

const Home = () => {

    const { t } = useTranslation()

    const comments = [
        {
            name: "Մարի",
            comment: "Ես շատ գոհ եմ ալեքսանդրիտից, 4-րդ այցից վերացան ողջ մազերը, գոհ եմ նաև մասնագետների բարեխիղճ և մանրակրկիտ աշխատանքի համար։ Խորհուրդ եմ տվել իմ ընկերներին, որ իրենք էլ այցելեն։ Շնորհակալ եմ շաաաատ:"
        },
        {
            name: "Nune",
            comment: "Я очень довольна обслуживающим персоналом и результатом процедуры. Перед процедурой я проконсультировалась с врачом и мы получили желаемый результат. Я так рада иметь желанный вид благодаря @Medroom ❤️🫶🏻"
        },
        {
            name: "Rima",
            comment: "Это самая лучшая клиника за уходом и процедурами ! Очень грамотные и специализированные врачи , что первоочередно для доверия  своей кожи !) спасибо вам 🙏🏽"
        },
        {
            name: "Anna",
            comment: "Գեղեցկություն և առողջություն մեկ վայրում MEDROOM Beauty and Health in one place MEDROOM Красота и здоровье два в одном. MEDROOM Thank you DR. Nune Davtyan  ⭐⭐⭐⭐⭐💖💖💖💖💖"
        },
        {
            name: "Armin",
            comment: "Շնորհակալ եմ հաճելի միջավայր ապահովելու և պրոֆեսիոնալ մասնագետների գրագետ մոտեցման համար💜💜💜"
        },
        {
            name: "Lilit",
            comment: "Հրաշալի սպասարկում և մոտեցում, այսօր իմ առաջին այցն էր ու ես անչափ շնորհակալ եմ🥰այսուհետ Ձեր մշտական հաճախորդն եմ❤️"
        },
    ]

    return (
        <Wrapper>

            <header>
                <Navigation />
                <MobileNavigation />
                <img width={"100%"} height={"auto"} src="./Assets/Images/header.webp" alt="" />
            </header>

            <Main>
                
                <Subtitle> {t("subtitle1")} <span className="clinicName"> MEDROOM </span> </Subtitle>
                <p className="discountText"> {t("discountText")} </p>
                <div className="welcomeImages">
                    <img width={"30%"} height={"auto"} src="./Assets/Images/image1.webp" alt="" />
                    <img width={"30%"} height={"auto"} src="./Assets/Images/image2.webp" alt="" />
                    <img width={"30%"} height={"auto"} src="./Assets/Images/image3.webp" alt="" />
                </div>

                <Subtitle id="medroom"> {t("subtitle2")} </Subtitle>
                <div className="aboutUsCard">
                    <div className="aboutUsTextsCard">   
                        <p className="aboutUsText"> <strong> {t("aboutUsText1")} </strong> </p>
                        <p className="aboutUsText"> {t("aboutUsText2")} </p>
                        <p className="aboutUsText"> {t("aboutUsText3")} </p>
                    </div>
                    <img width={"45%"} height={"auto"} src="./Assets/Images/image4.webp" alt="" />
                </div>

                <Subtitle> {t("subtitle3")} </Subtitle>
                <div className="careMethodsCard">
                    <img width={"auto"} height={"auto"} src="./Assets/images/Image5.webp" alt="" />
                    <img width={"auto"} height={"auto"} src="./Assets/images/Image6.webp" alt="" />
                    <img width={"auto"} height={"auto"} src="./Assets/images/Image7.webp" alt="" />
                </div>

                <Subtitle> {t("subtitle4")} </Subtitle>
                <div className="advantagesCard">
                    <AdvantageCard 
                        src="./Assets/Icons/icon1.png"
                        text1 = {t("advantageBoldText1")}
                        text2 = {t("advantageText1")}
                    />
                    <AdvantageCard 
                        src="./Assets/Icons/icon2.png"
                        text1 = {t("advantageBoldText2")}
                        text2 = {t("advantageText2")}
                    />
                    <AdvantageCard 
                        src="./Assets/Icons/icon3.png"
                        text1 = {t("advantageBoldText3")}
                        text2 = {t("advantageText3")}
                    />
                    <AdvantageCard 
                        src="./Assets/Icons/icon4.png"
                        text1 = {t("advantageBoldText4")}
                        text2 = {t("advantageText4")}
                    />
                </div>

                <Subtitle id="services"> {t("subtitle5")} </Subtitle>

                <div className="servicesCard">
                    <Link to={"HairTransplantation"} className="serviceCard">
                        <img src="./Assets/Images/image8.webp" alt="" />
                        <p className="serviceName"> {t("service1")} </p>
                    </Link>
                    <div className="serviceCard">
                        <img src="./Assets/Images/image9.webp" alt="" />
                        <p className="serviceName"> {t("service2")} </p>
                    </div>
                    <div className="serviceCard">
                        <img src="./Assets/Images/image10.webp" alt="" />
                        <p className="serviceName"> {t("service3")} </p>
                    </div>
                    <div className="serviceCard">
                        <img src="./Assets/Images/image11.webp" alt="" />
                        <p className="serviceName"> {t("service4")} </p>
                    </div>
                </div>
            </Main>

            <Comments comments={comments} />

            <Subtitle id="contacts"> {t("subtitle6")} </Subtitle>
            <Main>
                <GmailForm />
            </Main>

            <Footer />
        </Wrapper>
    )
}

export default Home