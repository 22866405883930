import { useRef, useState } from "react"
import emailjs from '@emailjs/browser';
import "./GmailForm.css"
import { useTranslation } from "react-i18next";

const GmailForm = () => {

    const { t } = useTranslation();

    const [btnColor,setBtnColor] = useState("sendButton")

    const [isSent,setIsSent] = useState(false)
    const [disabled,setDisabled] = useState(false)


    const form = useRef()

    const sendGmail = (event) => {

        event.preventDefault()

        emailjs.sendForm('service_kxuzs6q', 'template_lph3aed', form.current, {
            publicKey: 'AG1tEoBb9VxQ2PQ7C',
        })

        setIsSent(true)
        setDisabled(true)
        setBtnColor("sendButtonGreen")
    }

    return (
        <form ref={form} className="gmailForm" onSubmit={sendGmail}>
            <input placeholder={t("input1")} className="gmailFormInput" type="text" required name="name_lastname"/>
            <input placeholder={t("input2")} className="gmailFormInput" type="tel" required name="tel_number" />
            <input placeholder={t("input3")} className="gmailFormInput" type="email" name="mail" />
            <button 
                disabled={disabled}
                className={btnColor} type="submit"> { isSent === false ? t("sendButton") : t("sent") } </button>
        </form>
    )
}

export default GmailForm