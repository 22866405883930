import "./AdvantageCard.css"

const AdvantageCard = (props) => {
    return (
        <div className="advantageCard">
            <img src={props.src} alt="" />
            <p className="advantageText1"> {props.text1} </p>
            <p className="advantageText2"> {props.text2} </p>
        </div>
    )
}

export default AdvantageCard