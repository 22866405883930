import { useState } from "react"
import Wrapper from "../Wrapper"
import "./MobileNavigation.css"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const MobileNavigation = () => {

    const [screenBlocker, setScreenBlocker] = useState("none")
    const [nav, setNav] = useState("nav")

    const { t, i18n } = useTranslation()

    const changeLanguage = (language) => {
        i18n.changeLanguage(language)
    };

    const [language1, setLanguage1] = useState({ text: "Հայ", lng: "arm" })
    const [language2, setLanguage2] = useState({ text: "Ру", lng: "ru" })
    const [language3, setLanguage3] = useState({ text: "En", lng: "en" })

    return (
        <Wrapper>
            <nav className="mobileNavigation">
                <Link to={"/"}>
                    <svg className="mobileLogo" width="119" height="60" viewBox="0 0 119 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M66 0.453212C72.3179 0.361316 78.6334 0.281696 84.9514 0.233486C87.0999 0.213386 89.2484 0.186102 91.3967 0.151454C94.4873 0.102863 97.5771 0.0802264 100.668 0.0625872C101.626 0.0419421 102.585 0.0212969 103.573 2.61813e-05C107.854 -0.00141018 110.321 0.0479023 114.06 2.27841C117.153 5.74551 117.968 9.11605 118.031 13.7306C117.698 18.7434 116.834 21.9387 113 25.4532C108.276 28.0444 103.561 27.7091 98.3242 27.6485C96.9365 27.6443 96.9365 27.6443 95.5207 27.6399C92.5762 27.6288 89.6319 27.6037 86.6875 27.5782C84.6869 27.5682 82.6862 27.5591 80.6855 27.5509C75.7902 27.5306 70.8954 27.4914 66 27.4532C66 18.5432 66 9.63321 66 0.453212ZM68 2.45321C68 9.71321 68 16.9732 68 24.4532C73.7487 24.5681 79.4935 24.6676 85.2424 24.7279C87.197 24.753 89.1515 24.7871 91.1057 24.8304C93.9184 24.8912 96.7298 24.9195 99.543 24.9415C100.414 24.9673 101.285 24.9931 102.182 25.0197C106.652 25.0218 108.414 24.9188 112.029 22.0477C115.184 17.8946 115.542 14.5889 115 9.45321C113.727 6.37164 112.879 4.93381 109.95 3.33261C105.914 2.1294 102.169 2.20563 97.9805 2.2579C96.6926 2.26214 96.6926 2.26214 95.3787 2.26648C92.6482 2.27763 89.918 2.30273 87.1875 2.32821C85.3314 2.33825 83.4753 2.34737 81.6191 2.35556C77.0792 2.37585 72.54 2.41501 68 2.45321Z" fill="#13A2B7" />
                        <path d="M86 31.4532C91 32.4532 91 32.4532 92.4856 34.2791C92.8784 35.0572 93.2711 35.8352 93.6758 36.6368C94.1108 37.4818 94.5459 38.3268 94.9941 39.1974C95.4292 40.0862 95.8643 40.975 96.3125 41.8907C96.7669 42.7757 97.2213 43.6606 97.6895 44.5724C101 51.1088 101 51.1088 101 53.4532C101.66 53.4532 102.32 53.4532 103 53.4532C103.235 52.636 103.469 51.8187 103.711 50.9767C105.267 46.7225 107.362 42.9895 109.625 39.0782C110.045 38.3422 110.465 37.6061 110.898 36.8477C111.928 35.0468 112.963 33.2496 114 31.4532C115.32 31.4532 116.64 31.4532 118 31.4532C118 40.3632 118 49.2732 118 58.4532C117.01 58.4532 116.02 58.4532 115 58.4532C114.505 49.0482 114.505 49.0482 114 39.4532C110.7 45.7232 107.4 51.9932 104 58.4532C102.68 58.4532 101.36 58.4532 100 58.4532C99.6133 57.6682 99.2266 56.8831 98.8281 56.0743C98.3073 55.0237 97.7866 53.9731 97.25 52.8907C96.4843 51.3419 96.4843 51.3419 95.7031 49.7618C93.9058 46.2702 91.9483 42.8628 90 39.4532C89.67 45.7232 89.34 51.9932 89 58.4532C88.01 58.4532 87.02 58.4532 86 58.4532C86 49.5432 86 40.6332 86 31.4532Z" fill="#13A2B7" />
                        <path d="M0 0.453217C5 1.45322 5 1.45322 6.57739 3.40268C7.01205 4.23533 7.4467 5.06799 7.89453 5.92587C8.37471 6.82499 8.85488 7.72411 9.34961 8.65048C9.83236 9.59601 10.3151 10.5415 10.8125 11.5157C11.3146 12.4651 11.8167 13.4145 12.334 14.3927C13.5717 16.7385 14.7927 19.0916 16 21.4532C16.307 20.8526 16.6141 20.252 16.9304 19.6331C18.3315 16.9176 19.759 14.2169 21.1875 11.5157C21.9116 10.0974 21.9116 10.0974 22.6504 8.65048C23.3707 7.3018 23.3707 7.3018 24.1055 5.92587C24.5401 5.09322 24.9748 4.26057 25.4226 3.40268C27 1.45322 27 1.45322 32 0.453217C32 9.03322 32 17.6132 32 26.4532C31.01 26.7832 30.02 27.1132 29 27.4532C28.505 18.0482 28.505 18.0482 28 8.45322C22.4617 17.9311 22.4617 17.9311 17 27.4532C14.0119 26.4013 13.0694 25.568 11.4023 22.8087C10.6772 21.2405 10.6772 21.2405 9.9375 19.6407C8.10563 15.7292 6.2355 12.179 4 8.45322C3.67 14.7232 3.34 20.9932 3 27.4532C2.01 27.1232 1.02 26.7932 0 26.4532C0 17.8732 0 9.29322 0 0.453217Z" fill="#13A2B7" />
                        <path d="M34 0.453217C43.57 0.453217 53.14 0.453217 63 0.453217C63 1.11322 63 1.77322 63 2.45322C54.42 2.45322 45.84 2.45322 37 2.45322C37 5.42322 37 8.39322 37 11.4532C45.25 11.4532 53.5 11.4532 62 11.4532C62 12.4432 62 13.4332 62 14.4532C53.75 14.4532 45.5 14.4532 37 14.4532C37 17.7532 37 21.0532 37 24.4532C45.58 24.4532 54.16 24.4532 63 24.4532C63 25.4432 63 26.4332 63 27.4532C53.43 27.4532 43.86 27.4532 34 27.4532C34 18.5432 34 9.63322 34 0.453217Z" fill="#13A2B7" />
                        <path d="M0 31.4532C3.0525 31.3913 6.105 31.3295 9.25 31.2657C10.6833 31.2247 10.6833 31.2247 12.1455 31.1829C15.7348 31.1476 18.324 31.1234 21.5859 32.7149C23.2871 34.8061 23.4862 35.7835 23.375 38.4532C23.3557 39.4741 23.3557 39.4741 23.3359 40.5157C23 42.4532 23 42.4532 21 45.4532C21.66 46.1132 22.32 46.7732 23 47.4532C23.4141 50.0548 23.4141 50.0548 23.625 53.0782C23.6998 54.0811 23.7745 55.084 23.8516 56.1173C23.925 57.2736 23.925 57.2736 24 58.4532C22.68 58.1232 21.36 57.7932 20 57.4532C19.67 54.4832 19.34 51.5132 19 48.4532C15.6846 47.3481 12.7959 47.1321 9.3125 46.8907C8.13301 46.8069 6.95352 46.7231 5.73828 46.6368C4.83465 46.5762 3.93102 46.5156 3 46.4532C3 50.4132 3 54.3732 3 58.4532C2.01 58.4532 1.02 58.4532 0 58.4532C0 49.5432 0 40.6332 0 31.4532ZM3 34.4532C3 37.7532 3 41.0532 3 44.4532C5.08305 44.5074 7.1665 44.5461 9.25 44.5782C10.4102 44.6014 11.5703 44.6246 12.7656 44.6485C15.8279 44.4636 17.442 44.0614 20 42.4532C20 40.1432 20 37.8332 20 35.4532C16.9347 33.9205 13.7325 34.3504 10.375 34.3907C9.31539 34.3975 9.31539 34.3975 8.23438 34.4044C6.48955 34.4162 4.74476 34.4341 3 34.4532Z" fill="#13A2B7" />
                        <path d="M47.1289 32.3126C50.2765 34.2314 51.7616 37.0936 53 40.4532C53.6687 44.752 53.5097 48.2462 51.8125 52.2657C48.4255 56.3535 45.8145 58.569 40.5 59.3282C36.7952 59.5736 34.2421 59.4345 31 57.4532C25.1694 52.1527 25.1694 52.1527 24.7617 48.0626C24.6426 43.4726 24.6687 40.1954 26.5625 35.9532C32.4592 29.9053 39.5221 29.2419 47.1289 32.3126ZM30 37.4532C27.9268 40.5631 27.7261 41.3443 27.75 44.8907C27.7449 45.6526 27.7397 46.4144 27.7344 47.1993C28.0319 49.7236 28.6546 51.3042 30 53.4532C34.2924 56.4893 37.815 56.9566 42.875 56.1446C45.6798 55.2321 47.5044 53.7575 49.1875 51.3321C50.7515 47.7153 50.7699 44.2815 50 40.4532C47.9299 36.9784 45.8837 34.7097 42 33.4532C36.7351 32.7665 34.1014 34.1908 30 37.4532Z" fill="#13A2B7" />
                        <path d="M76 31.4532C79.3042 33.2517 81.4794 35.554 83.375 38.8282C84.4848 43.4894 84.4933 47.129 82.8125 51.6407C80.3996 55.3848 78.3385 57.8114 73.9219 59.0235C68.5287 59.8857 64.7754 59.3559 60 56.4532C56.7665 52.7943 55.224 49.3566 54.8359 44.543C55.1408 40.6602 56.7147 37.5624 59 34.4532C64.4856 30.3056 69.4678 29.8257 76 31.4532ZM60 37.4532C57.6166 41.0283 57.7438 44.3971 58.3828 48.5196C59.4 51.7063 61.4529 53.4031 64 55.4532C67.1095 57.008 69.5627 56.7396 73 56.4532C76.7039 54.4265 78.6536 52.9727 81 49.4532C81.4274 44.1534 81.5235 40.7057 78 36.4532C75.3138 34.3478 73.5003 33.5293 70.125 33.0157C65.8116 33.6196 63.569 34.9137 60 37.4532Z" fill="#13A2B7" />
                    </svg>
                </Link>
                <button
                    className="burgerBarButton"
                    onClick={() => {
                        document.body.style.overflowY = "hidden"
                        setScreenBlocker("screenBlocker")
                    }}
                >
                    <svg className="burgerBarIcon" width="46" height="36" viewBox="0 0 46 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 33H43" stroke="#F6F6F6" strokeWidth="5" strokeLinecap="round" />
                        <path d="M3 18H43" stroke="#F6F6F6" strokeWidth="5" strokeLinecap="round" />
                        <path d="M3 3H43" stroke="#F6F6F6" strokeWidth="5" strokeLinecap="round" />
                    </svg>
                </button>
            </nav>

            <section
                className={screenBlocker}
                onClick={(event) => {
                    // console.log(event.target.className);
                    if (event.target.className === "screenBlocker") {
                        setNav("navClose")
                        setTimeout(() => {
                            setScreenBlocker("none")
                            document.body.style.overflowY = "auto"
                            setNav("nav")
                        }, 500)
                    }
                }}
            >
                <div className={nav}>
                    <button
                        className="closeButton"
                        onClick={() => {
                            setNav("navClose")
                            setTimeout(() => {
                                setScreenBlocker("none")
                                document.body.style.overflowY = "auto"
                                setNav("nav")
                            }, 500)
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#f6f6f6" className="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                        </svg>
                    </button>

                    <div className="mobileNavItems">
                        <Link
                            className="mobileNavLink"
                            to={"/"}
                            onClick={() => {
                                setNav("navClose")
                                setTimeout(() => {
                                    setScreenBlocker("none")
                                    document.body.style.overflowY = "auto"
                                    setNav("nav")
                                }, 500)
                            }}
                        > {t("navLink1")} </Link>
                        <a
                            className="mobileNavLink"
                            href="#medroom"
                            onClick={() => {
                                setNav("navClose")
                                setTimeout(() => {
                                    setScreenBlocker("none")
                                    document.body.style.overflowY = "auto"
                                    setNav("nav")
                                }, 500)
                            }}
                        > {t("navLink2")} </a>
                        <a
                            className="mobileNavLink"
                            href="#services"
                            onClick={() => {
                                setNav("navClose")
                                setTimeout(() => {
                                    setScreenBlocker("none")
                                    document.body.style.overflowY = "auto"
                                    setNav("nav")
                                }, 500)
                            }}
                        > {t("navLink3")} </a>
                        <a
                            className="mobileNavLink"
                            href="#contacts"
                            onClick={() => {
                                setNav("navClose")
                                setTimeout(() => {
                                    setScreenBlocker("none")
                                    document.body.style.overflowY = "auto"
                                    setNav("nav")
                                }, 500)
                            }}
                        > {t("navLink5")} </a>
                    </div>

                    <div className="mobileNavLanguages">
                        <button className="changeLanguageButtonMobile" onClick={() => { changeLanguage(language1.lng) }}> {language1.text} </button>
                        <button className="changeLanguageButtonMobile" onClick={(e) => { changeLanguage(language2.lng) }}> {language2.text} </button>
                        <button className="changeLanguageButtonMobile" onClick={() => { changeLanguage(language3.lng) }}> {language3.text} </button>
                    </div>

                </div>
            </section>
        </Wrapper>
    )
}

export default MobileNavigation