import { useState } from "react"
import "./Comments.css"
import { useTranslation } from "react-i18next"

const Comments = (props) => {

    const [t] = useTranslation()

    const [sliderItemIndex, setSliderItemIndex] = useState(0)

    const nextSliderElement = () => {
        if (sliderItemIndex === props.comments.length - 1) {
            return setSliderItemIndex(0)
        }
        else {
            return setSliderItemIndex(sliderItemIndex + 1)
        }
    }

    const prevSliderElement = () => {
        if (sliderItemIndex === 0) {
            return setSliderItemIndex(props.comments.length - 1)
        } else {
            return setSliderItemIndex(sliderItemIndex - 1)
        }
    }

    return (
        <div className="comments">
            <h2 className="subtitle2"> {t("comment")} </h2>
            <div className="flexDiv">
                <button className="arrowLeft" onClick={prevSliderElement}>
                    <svg width="80" height="80" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M52 2C24.3858 2 2 24.3858 2 52C2 79.6142 24.3858 102 52 102C79.6142 102 102 79.6142 102 52C102 24.3858 79.6142 2 52 2Z" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M67.817 20.3676L36.1829 51.9997L67.817 83.6318" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>

                <div className="sliderWrapper">



                    <div className="sliderRow">
                        <div className="sliderVisible">
                            {
                                props.comments.map((el, index) => {
                                    return (
                                        <div key={index} style={{ translate: `${-100 * sliderItemIndex}%` }} className="sliderD">
                                            <p className="commentName"> {el.name} </p>
                                            <p className="commentText"> {el.comment} </p>
                                            <svg className="stars" width="100%" height="50" viewBox="0 0 350 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M25 0L30.6129 17.2746H48.7764L34.0818 27.9508L39.6946 45.2254L25 34.5491L10.3054 45.2254L15.9182 27.9508L1.22359 17.2746H19.3871L25 0Z" fill="#FFD700" />
                                                <path d="M100 0L105.613 17.2746H123.776L109.082 27.9508L114.695 45.2254L100 34.5491L85.3054 45.2254L90.9182 27.9508L76.2236 17.2746H94.3871L100 0Z" fill="#FFD700" />
                                                <path d="M175 0L180.613 17.2746H198.776L184.082 27.9508L189.695 45.2254L175 34.5491L160.305 45.2254L165.918 27.9508L151.224 17.2746H169.387L175 0Z" fill="#FFD700" />
                                                <path d="M250 0L255.613 17.2746H273.776L259.082 27.9508L264.695 45.2254L250 34.5491L235.305 45.2254L240.918 27.9508L226.224 17.2746H244.387L250 0Z" fill="#FFD700" />
                                                <path d="M325 0L330.613 17.2746H348.776L334.082 27.9508L339.695 45.2254L325 34.5491L310.305 45.2254L315.918 27.9508L301.224 17.2746H319.387L325 0Z" fill="#FFD700" />
                                            </svg>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>


                </div>

                <button className="arrowRight" onClick={nextSliderElement}>
                    <svg width="80" height="80" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M52 102C79.6142 102 102 79.6142 102 52C102 24.3858 79.6142 2 52 2C24.3858 2 2 24.3858 2 52C2 79.6142 24.3858 102 52 102Z" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M36.183 83.6324L67.8171 52.0003L36.183 20.3682" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>


        </div>


    )
}

export default Comments